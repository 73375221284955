.manage-fund-request {

  &__row-form {
    display: flex;
    flex-direction: row;
  }

  &__form-content {
    display: flex;
    width: calc(50% - #{$gutter-small / 2});
    flex-direction: row;
    margin-right: $gutter-small;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  table{
    width:100%;
    table-layout: fixed;
  }

  &__table-header {
    background-color: rgba(black, 0.3);
  }

  &__table-content {
    height: 40rem;
    overflow-y: auto;
    margin-top: 0;
    border: 1px solid rgba(black, 0.5);
  }

  th {
    font-size: $font-size-default;
    padding: 2rem 1.5rem;
    text-align: left;
    border: 1px solid rgba(black, 0.5);
  }

  td {
    font-size: $font-size-default;
    padding: 1.5rem;
    text-align: left;
    vertical-align: middle;
    border: 1px solid rgba(black, 0.5);
    &.nominal{
      text-align: right;
      input, .input__container {
        text-align: right;
      }
      .input__preview{
        display: block;
        text-align: right;
        width: 100%;
      }
    }
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 5%;
      text-align: center;
    }
  }

  &__form-table {
    margin-bottom: $gutter-default;
  }

  &__total-transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
