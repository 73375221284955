.login {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../img/background-merge2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  &__simpeel-logo {
    height: 100%;
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: auto;
    }
  }

  &__form {
    background-color: rgba($color-white, .3);
    height: 100vh;
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: $gutter-medium;
  }

  &__form-title {
    margin-top: $gutter-default;
    margin-bottom: $gutter-small;
    font-size: 2.4rem;
    color: $color-white;
  }

  &__input-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: $gutter-default;

    &--icon {
      color: $color-white;
      padding: 0 $gutter-small;
      font-size: $font-size-medium;
    }
  }
}
