.select {
  width: 100%;
  margin-bottom: $gutter-extra-small;

  &__no-margin {
    margin-bottom: 0;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--error {

    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__input {
    padding: 0.9rem 1.2rem;
    font-size: $font-size-default;
    background-color: $color-white;
    border-radius: 4px;
    background-clip: padding-box;
    border: $color-fade-border;
    outline: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-appearance: button;
    -webkit-user-select: none;

    &--center {
      text-align-last: center;
    }

    &:active,
    &:focus {
      outline: none;
    }

    option {
      color: $color-primary-dark;
    }
  }

  &__arrow {
    color: $color-black;
    font-size: $font-size-default;
  }

  &__err {
    font-size: $font-size-default;
    color: $color-red;
  }

  &__placeholder {
    color: $color-placeholder;
  }
}
