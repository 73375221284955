.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}


.table2 {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    //border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.table3 {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}


.table0 {
  
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    //border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.table6 {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}



.table5 {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-filters {
  display: flex;
  & > * {
    margin-right: 1rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
}


.table-daftarnilai {
  border-collapse: collapse;
  width: 200%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-raport {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }
  
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-signature {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}


.table-report-header {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: none;
    padding: 0;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-anekdot {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    //border: 1px solid $color-black;
    padding: 8px;
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.table-indikator {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}