.checkbox {
  margin-bottom: $gutter-extra-small;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;

    &--error {

    }

    &--center {
      justify-content: center;
    }
  }

  &__label {
    font-size: $font-size-small;

    &--error {
      color: $color-red;
    }
  }

  &__icon-left {
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-default;
    border: none;
    background-color: $color-black-4;
    color: $color-black-5;
    border-radius: 4px 0 0 4px;
  }

  &__text {
    padding: 0.9rem 1.2rem;
    font-size: $font-size-default;
    background-color: $color-black-2;
    border-radius: 4px;
    background-clip: padding-box;
    color: $color-white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    outline: none;
    width: 100%;

    &--icon-left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--icon-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--no-border {
      border: 0;
    }

    &--disabled {
      color: $color-disabled;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__icon-right {
    padding: 1.2rem 1rem;
    font-size: $font-size-default;
    border: none;
    background-color: $color-black-4;
    color: $color-black-5;
    border-radius: 0 4px 4px 0;
  }

  &__err {
    font-size: $font-size-default;
    color: $color-red;
  }

  &__disabled {

  }

  &__no-margin {
    margin-bottom: 0;
  }

  &__content {
    position: relative;
    cursor: pointer;
    font-size: $font-size-default;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: 5px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkbox__checkmark {
        background-color: $color-primary-dark;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      &:checked ~ .checkbox__checkmark:after {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover input ~ .checkbox__checkmark {
      background-color: $color-grey-dark-2;
    }

    .checkbox__checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: $color-grey-dark-3;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
}
