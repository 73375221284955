.button {
  &,
  &:link,
  &:visited {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  .modal { /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background-color: #451b04;
    color: white;
}

.modal-body {padding: 20px 16px;}

.modal-footer {
    padding: 2px 16px;
    background-color: #451b04;
    color: white;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
  

  &:hover {
    background-color: $color-primary-light;
  }

  &:active {
    background-color: $color-primary-dark;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &__icon {
    margin-right: 5px;

    &--no-margin {
      margin-right: 0;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.button2 {
  &,
  &:link,
  &:visited {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    font-size: $font-size-default;
    color: $color-white;
    // text-transform: uppercase;
    text-decoration: none;
    padding: 8px 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary-light;
  }

  &:active {
    background-color: $color-primary-dark;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &__icon {
    margin-right: 5px;

    &--no-margin {
      margin-right: 0;
    }
  }
}

.button-transparent {
  &,
  &:link,
  &:visited {
    background-color: transparent;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.button-square {
  &,
  &:link,
  &:visited {
    width: 50px;
    height: 50px;
    background-color: transparent;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.button-white {
  &,
  &:link,
  &:visited {
    background-color: $color-white;
    color: $color-primary-dark;
  }

  &:hover {
    background-color: $color-grey-light-1;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.button-disabled {
  opacity: 0.65;
  pointer-events: none;
}
