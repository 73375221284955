.individual {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__field{
      display: flex;
      width: 50%;
      margin-left: 3rem;
    }
    &__field2{
      display: flex;
      width: 50%;
      margin-left: 8rem;
    }


    &__custom-form-row-input {
        display: flex;
        flex-direction: row;
        
        &__field {
            display: flex;
            flex-direction: row;
            flex: 1;
            width: 50%;
            &:not(:nth-child(1)) {
              margin-left: 1rem;
            }
          }
    }

    &__custom-form-margin-left {
        display: flex;
        flex-direction: row;
        margin-left: 1rem;
        width: 100%;
       
    }


    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-row-check {
        display: flex;
        flex-direction: row;
        margin-left: 0rem;
        margin-top: 2rem;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          &:not(:nth-child(1)) {
            margin-left: -2rem;
          }
        }
      }

    &__body {
        margin-top: $gutter-extra-small;
      }
    
      &__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: $gutter-default;
        
    
        td, th {
          border: 1px solid $color-black;
          padding: 8px;
         
          color: $color-black;
        }
    
        tr td, tr th {
          &:nth-child(1) {
            width: 1%;
          }
        }
    
        td.nominal {
            border: none;
        }
    
        td {
          font-size: $font-size-default;
          
        }
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
        }
      }

      &__table-mini {
        border: none;
        width: 100%;
        margin-bottom: $gutter-default;
        
    
        td, th {
          padding: 0px;
         
          color: $color-black;
        }
    
        tr td, tr th {
          &:nth-child(1) {
            width: 1%;
          }
        }
    
        td.nominal {
          text-align: right;
        }
    
        td {
            border: none;
          font-size: $font-size-default;
          text-align: center;
          
        }
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }
      }



  }
  