.form {
    &-group {
      display: flex;
      flex-direction: column;
      justify-content: bottom;
      &-member {
        margin-right: $gutter-small;
        align-self: flex-end;
        &:last-child {
          margin-right: 0;
        }
        & > * {
          margin-bottom: 0;
        }
  
        button {
          margin-bottom: 3px;
        }
      }
      &.inline {
        flex-direction: row;
        flex-wrap: wrap;
        flex:1 0 100%;
        margin-right: 0;
        .form-group-member {
          flex-direction: column;
          flex-grow: 1;
          &::first{
            margin-right: 2rem;
          }
          .form-group.inline {
            flex: 1 0 auto;
          }
        }
        .form-group-label {
          flex: 0 1 100%;
        }
  
      }
    }
  }
  