.modal {
  visibility: hidden;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &__overlay {
    z-index: 998;
    visibility: hidden;
    position: fixed;
    background-color: $color-black;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .15s linear;

    &--show {
      visibility: visible;
      background-color: $color-black;
      opacity: .5;
    }
  }

  &__button-container {
    width: 100%;
  }

  &__button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2rem 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: $color-primary-light;
    }

    &:active {
      background-color: $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__content {
    position: relative;
    background-color: $color-black-3;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    opacity: 0;
    transition: all .15s linear;

    &--show {
      visibility: visible;
      background-color: $color-black;
      opacity: 1;
    }
  }

  &__header {
    padding: $gutter-small;
    border-bottom: 1px solid $color-fade-white;
  }

  &__body {
    padding: $gutter-small;
    border-bottom: 1px solid $color-fade-white;
  }

  &__footer {
    padding: $gutter-small;
  }

  &__close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.modal2 {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  //z-index:99999;
  opacity: 1;
  display: none; /* Hidden by default */
  //position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  //left: 0;
  //top: 0;
  //width: 100%; /* Full width */
  //height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal2-main {
  position:fixed;
  background: white;
  width: 50%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal2 > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}


.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(195, 119, 119);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}