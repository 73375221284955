.control-table2 {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__custom-form-row2 {
        display: flex;
        flex-direction: row;
    
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
    
          &:not(:nth-child(1)) {
            margin-left: 2rem;
          }
        }
      }

      &__custom-form-row3 {
        display: flex;
        flex-direction: row;
        width:24%;
        
      }

      &__custom-form-row4 {
        display: flex;
        flex-direction: row;
        width:24%;
        margin-left:1rem;
        margin-top:2rem;
       
      }

      &__custom-form-row5 {
        display: flex;
        flex-direction: row;
        width:50%;
        
      }

      &__custom-form-row6 {
        display: flex;
        flex-direction: row;
        width:50%;
        margin-left:1rem;
        margin-top:2rem;
       
      }

    &__custom-form-row-check {
        display: flex;
        flex-direction: row;
        
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          &:not(:nth-child(1)) {
            margin-left: -2rem;
          }
        }
      }

      &__custom-form-row-select {
        display: flex;
        flex-direction: row;
        width: 200%;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          
        }
      }

      &__custom-select {
        display: flex;
        flex-direction: row;
        
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-left:1rem;
        //   &:not(:nth-child(1)) {
        //     margin-left:5rem;
        //   }
        }
      }

      &__custom-font {
        display: flex;
        font-size: 15px;
        flex-direction: column;
        &__field {
          display: flex;
          flex-direction: column;
          flex: 1;
    
          &:not(:nth-child(1)) {
            margin-left:-10rem;
          }
        }
      }

      &__custom-checkbox {
        display: flex;
        flex-direction: row;
    
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-left:2rem;
        //   &:not(:nth-child(1)) {
        //     margin-left:5rem;
        //   }
        }
      }
  

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-column2 {
        display: flex;
        flex-direction: row;
        width: 52%;
        margin-left: 47rem;
       
      }

    &__custom-form-data-row{
        display: flex;
        flex-direction: row;
        width: 300px;
        margin-left: 3rem;
        &__field {
          display: flex;
          flex-direction: row;
          flex: 1;
    
        }
    }

    &__content {
        position: relative;
        width: 500px;
      }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }
  }
  