@import url('https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap');

.menu {
  font-size: 1.5rem;
  width: 100%;
  padding: 1rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba($color-grey-dark-2, .3);
  font-family: 'Poppins', sans-serif;

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__left-icon {
    font-size: $font-size-large;
    color: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 30px;
      height: 30px;
    }

    &--small {
      font-size: $font-size-small;
    }
  }

  &__title  {
    margin-left: 3px;
    font-size: 1.5rem;
    
  }
}
