.laporan-pegawai {

    &__custom-form {
  
    }
  
    &__custom-form-row {
      display: flex;
      flex-direction: row;
  
      &__field {
        display: flex;
        flex-direction: row;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: 0rem;
        }
      }
    }

    &__custom-form-column {
      display: flex;
      flex-direction: column;
  
      &__field {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        &:not(:nth-child(1)) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-column2 {
        display: flex;
        flex-direction: column;
        
        &__field {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 2rem;
    
          &:not(:nth-child(1)) {
            margin-left: $gutter-small;
          }
        }
      }

    &__custom-p{
        margin-top: 3rem;
        margin-left: 1rem;
    }
  
    &__button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
  
      button {
        &:nth-child(2) {
          margin-left: $gutter-small;
        }
      }
    }

    &__custom-form-row6 {
      display: flex;
      flex-direction: row;
      width:50%;
      margin-left:1rem;
      margin-top: 2rem;
     
    }
    
    &__body {
        margin-top: 3rem;
      }
    
      &__table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: $gutter-default;
        
    
        td, th {
          border: 1px solid $color-black;
          padding: 8px;
         
          color: $color-black;
        }
  
      
        tr td, tr th {
          &:nth-child(1) {
            width: 1%;
          }
        }
    
        th.tanggal {
          text-align: center;
        }
        th.bulan {
            background-color: #2C8DE8;
            text-align: center;
        }
        th.angka{
            background-color: #B9BFC4;
            text-align: center;
        }
  
        td.text{
         color:red;
        }
  
    
        td {
          font-size: $font-size-default;
          
        }
  
        
    
        th {
          font-size: $font-size-default;
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }
      }

  }
  