.bookkeeping {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__list {
    margin-right: $gutter-small;

    img {
      width: 100px;
      height: 100px;
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-black;
      padding: $gutter-small;
      border-radius: 10px;
    }
  }
}
