.akademi {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
   
  
  &__list {
    margin-right: $gutter-small;
    margin-top: 20px;
    margin-bottom: 20px;
  
    img {
      width: 100px;
      height: 100px;
      }
  
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-black;
      padding: $gutter-small;
      border-radius: 10px;
      }
    }
  }
  