.home {
  padding: $gutter-small;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__logo {
    padding: $gutter-small;
    width: 25rem;
    height: 25rem;
  }
  &__logo2 {
    // background-image: url('../../../img/logo-kopsurat-ricci.png');
    // background-repeat: no-repeat;
    padding: $gutter-small;
    width: 15rem;
    height: 15rem;
    margin-left: 1;
  }
  
  &__logo3 {
    width: 7rem;
    height: 7rem;
  }

  &__info {
    padding: $gutter-small;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__info-list {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      &:nth-child(1) {
        flex: 2;
        font-weight: bold;
        font-size: $font-size-medium;
      }

      &:nth-child(2) {
        flex: 8;
        font-size: $font-size-medium;
      }
    }
  }
}
