.budget {

  &__content {
    margin-top: $gutter-default;
  }
  &__search {
    // display: flex;
    margin-left:110rem;
    width: 500px;
  }

  &__custom-form-row {
    display: flex;
    flex-direction: row;

    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: 0rem;
      }
    }
  }

  &__title {
    margin-bottom: 2%;
  }

  &__title2 {
    margin-bottom: 2%;
    
  }
}
